import { default as aboutWFzh9mlCZAMeta } from "/workspace/nuxt/pages/biennials/[slug]/index/about.vue?macro=true";
import { default as _91secondary_45slug_93dSqwJqmyBTMeta } from "/workspace/nuxt/pages/biennials/[slug]/index/education/[secondary-slug].vue?macro=true";
import { default as educationeKbIQC8WfPMeta } from "/workspace/nuxt/pages/biennials/[slug]/index/education.vue?macro=true";
import { default as insightsszvPuQIZZWMeta } from "/workspace/nuxt/pages/biennials/[slug]/index/insights.vue?macro=true";
import { default as _91id_937ktDGuWQy0Meta } from "/workspace/nuxt/pages/biennials/[slug]/index/participants/index/[id].vue?macro=true";
import { default as indexkO545T0McaMeta } from "/workspace/nuxt/pages/biennials/[slug]/index/participants/index.vue?macro=true";
import { default as urban_45visionTXBfNBlj23Meta } from "/workspace/nuxt/pages/biennials/[slug]/index/urban-vision.vue?macro=true";
import { default as indexgztIsxPakvMeta } from "/workspace/nuxt/pages/biennials/[slug]/index.vue?macro=true";
import { default as biennialsl6o7Fs4aOZMeta } from "/workspace/nuxt/pages/biennials.vue?macro=true";
import { default as _91slug_93JZssri3PmQMeta } from "/workspace/nuxt/pages/index/[slug].vue?macro=true";
import { default as _91slug_93WwbY4VEMEHMeta } from "/workspace/nuxt/pages/index/education/[slug].vue?macro=true";
import { default as education0AACABgkqWMeta } from "/workspace/nuxt/pages/index/education.vue?macro=true";
import { default as pressWQtAlW5EReMeta } from "/workspace/nuxt/pages/index/press.vue?macro=true";
import { default as urban_45visionw1P8QY7S1ZMeta } from "/workspace/nuxt/pages/index/urban-vision.vue?macro=true";
import { default as indexYicQbbOvh5Meta } from "/workspace/nuxt/pages/index.vue?macro=true";
import { default as _91slug_93CQQJCWyMWoMeta } from "/workspace/nuxt/pages/news/[slug].vue?macro=true";
import { default as newsJ8lbpdZQsmMeta } from "/workspace/nuxt/pages/news.vue?macro=true";
import { default as newsletterrSPdQGYG3oMeta } from "/workspace/nuxt/pages/newsletter.vue?macro=true";
import { default as page_45previewn2wjD0GievMeta } from "/workspace/nuxt/pages/page-preview.vue?macro=true";
import { default as _91slug_93rGsfMLgk71Meta } from "/workspace/nuxt/pages/participants-preview/[slug].vue?macro=true";
import { default as _91slug_93kyykW2ogSCMeta } from "/workspace/nuxt/pages/publications/[slug].vue?macro=true";
import { default as publicationsn6piZ0ljqSMeta } from "/workspace/nuxt/pages/publications.vue?macro=true";
export default [
  {
    name: biennialsl6o7Fs4aOZMeta?.name ?? "biennials___en",
    path: biennialsl6o7Fs4aOZMeta?.path ?? "/editions",
    children: [
  {
    name: indexgztIsxPakvMeta?.name ?? "biennials-slug___en",
    path: indexgztIsxPakvMeta?.path ?? ":slug()",
    children: [
  {
    name: aboutWFzh9mlCZAMeta?.name ?? "biennials-slug-index-about___en",
    path: aboutWFzh9mlCZAMeta?.path ?? "about",
    meta: aboutWFzh9mlCZAMeta || {},
    alias: aboutWFzh9mlCZAMeta?.alias || [],
    redirect: aboutWFzh9mlCZAMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/biennials/[slug]/index/about.vue").then(m => m.default || m)
  },
  {
    name: educationeKbIQC8WfPMeta?.name ?? "biennials-slug-index-education___en",
    path: educationeKbIQC8WfPMeta?.path ?? "education",
    children: [
  {
    name: _91secondary_45slug_93dSqwJqmyBTMeta?.name ?? "biennials-slug-index-education-secondaryslug___en",
    path: _91secondary_45slug_93dSqwJqmyBTMeta?.path ?? ":secondaryslug()",
    meta: _91secondary_45slug_93dSqwJqmyBTMeta || {},
    alias: _91secondary_45slug_93dSqwJqmyBTMeta?.alias || [],
    redirect: _91secondary_45slug_93dSqwJqmyBTMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/biennials/[slug]/index/education/[secondary-slug].vue").then(m => m.default || m)
  }
],
    meta: educationeKbIQC8WfPMeta || {},
    alias: educationeKbIQC8WfPMeta?.alias || [],
    redirect: educationeKbIQC8WfPMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/biennials/[slug]/index/education.vue").then(m => m.default || m)
  },
  {
    name: insightsszvPuQIZZWMeta?.name ?? "biennials-slug-index-insights___en",
    path: insightsszvPuQIZZWMeta?.path ?? "insights",
    meta: insightsszvPuQIZZWMeta || {},
    alias: insightsszvPuQIZZWMeta?.alias || [],
    redirect: insightsszvPuQIZZWMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/biennials/[slug]/index/insights.vue").then(m => m.default || m)
  },
  {
    name: indexkO545T0McaMeta?.name ?? "biennials-slug-index-participants___en",
    path: indexkO545T0McaMeta?.path ?? "participants",
    children: [
  {
    name: _91id_937ktDGuWQy0Meta?.name ?? "biennials-slug-index-participants-index-id___en",
    path: _91id_937ktDGuWQy0Meta?.path ?? ":id()",
    meta: _91id_937ktDGuWQy0Meta || {},
    alias: _91id_937ktDGuWQy0Meta?.alias || [],
    redirect: _91id_937ktDGuWQy0Meta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/biennials/[slug]/index/participants/index/[id].vue").then(m => m.default || m)
  }
],
    meta: indexkO545T0McaMeta || {},
    alias: indexkO545T0McaMeta?.alias || [],
    redirect: indexkO545T0McaMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/biennials/[slug]/index/participants/index.vue").then(m => m.default || m)
  },
  {
    name: urban_45visionTXBfNBlj23Meta?.name ?? "biennials-slug-index-urban-vision___en",
    path: urban_45visionTXBfNBlj23Meta?.path ?? "urban-vision",
    meta: urban_45visionTXBfNBlj23Meta || {},
    alias: urban_45visionTXBfNBlj23Meta?.alias || [],
    redirect: urban_45visionTXBfNBlj23Meta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/biennials/[slug]/index/urban-vision.vue").then(m => m.default || m)
  }
],
    meta: indexgztIsxPakvMeta || {},
    alias: indexgztIsxPakvMeta?.alias || [],
    redirect: indexgztIsxPakvMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/biennials/[slug]/index.vue").then(m => m.default || m)
  }
],
    meta: biennialsl6o7Fs4aOZMeta || {},
    alias: biennialsl6o7Fs4aOZMeta?.alias || [],
    redirect: biennialsl6o7Fs4aOZMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/biennials.vue").then(m => m.default || m)
  },
  {
    name: indexYicQbbOvh5Meta?.name ?? "index___en",
    path: indexYicQbbOvh5Meta?.path ?? "/",
    children: [
  {
    name: _91slug_93JZssri3PmQMeta?.name ?? "index-slug___en",
    path: _91slug_93JZssri3PmQMeta?.path ?? ":slug()",
    meta: _91slug_93JZssri3PmQMeta || {},
    alias: _91slug_93JZssri3PmQMeta?.alias || [],
    redirect: _91slug_93JZssri3PmQMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/index/[slug].vue").then(m => m.default || m)
  },
  {
    name: education0AACABgkqWMeta?.name ?? "index-education___en",
    path: education0AACABgkqWMeta?.path ?? "education",
    children: [
  {
    name: _91slug_93WwbY4VEMEHMeta?.name ?? "index-education-slug___en",
    path: _91slug_93WwbY4VEMEHMeta?.path ?? ":slug()",
    meta: _91slug_93WwbY4VEMEHMeta || {},
    alias: _91slug_93WwbY4VEMEHMeta?.alias || [],
    redirect: _91slug_93WwbY4VEMEHMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/index/education/[slug].vue").then(m => m.default || m)
  }
],
    meta: education0AACABgkqWMeta || {},
    alias: education0AACABgkqWMeta?.alias || [],
    redirect: education0AACABgkqWMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/index/education.vue").then(m => m.default || m)
  },
  {
    name: pressWQtAlW5EReMeta?.name ?? "index-press___en",
    path: pressWQtAlW5EReMeta?.path ?? "press",
    meta: pressWQtAlW5EReMeta || {},
    alias: pressWQtAlW5EReMeta?.alias || [],
    redirect: pressWQtAlW5EReMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/index/press.vue").then(m => m.default || m)
  },
  {
    name: urban_45visionw1P8QY7S1ZMeta?.name ?? "index-urban-vision___en",
    path: urban_45visionw1P8QY7S1ZMeta?.path ?? "urban-vision",
    meta: urban_45visionw1P8QY7S1ZMeta || {},
    alias: urban_45visionw1P8QY7S1ZMeta?.alias || [],
    redirect: urban_45visionw1P8QY7S1ZMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/index/urban-vision.vue").then(m => m.default || m)
  }
],
    meta: indexYicQbbOvh5Meta || {},
    alias: indexYicQbbOvh5Meta?.alias || [],
    redirect: indexYicQbbOvh5Meta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: newsJ8lbpdZQsmMeta?.name ?? "news___en",
    path: newsJ8lbpdZQsmMeta?.path ?? "/news",
    children: [
  {
    name: _91slug_93CQQJCWyMWoMeta?.name ?? "news-slug___en",
    path: _91slug_93CQQJCWyMWoMeta?.path ?? ":slug()",
    meta: _91slug_93CQQJCWyMWoMeta || {},
    alias: _91slug_93CQQJCWyMWoMeta?.alias || [],
    redirect: _91slug_93CQQJCWyMWoMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/news/[slug].vue").then(m => m.default || m)
  }
],
    meta: newsJ8lbpdZQsmMeta || {},
    alias: newsJ8lbpdZQsmMeta?.alias || [],
    redirect: newsJ8lbpdZQsmMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/news.vue").then(m => m.default || m)
  },
  {
    name: newsletterrSPdQGYG3oMeta?.name ?? "newsletter___en",
    path: newsletterrSPdQGYG3oMeta?.path ?? "/newsletter",
    meta: newsletterrSPdQGYG3oMeta || {},
    alias: newsletterrSPdQGYG3oMeta?.alias || [],
    redirect: newsletterrSPdQGYG3oMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: page_45previewn2wjD0GievMeta?.name ?? "page-preview___en",
    path: page_45previewn2wjD0GievMeta?.path ?? "/page-preview",
    meta: page_45previewn2wjD0GievMeta || {},
    alias: page_45previewn2wjD0GievMeta?.alias || [],
    redirect: page_45previewn2wjD0GievMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/page-preview.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93rGsfMLgk71Meta?.name ?? "participants-preview-slug___en",
    path: _91slug_93rGsfMLgk71Meta?.path ?? "/participants-preview/:slug()",
    meta: _91slug_93rGsfMLgk71Meta || {},
    alias: _91slug_93rGsfMLgk71Meta?.alias || [],
    redirect: _91slug_93rGsfMLgk71Meta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/participants-preview/[slug].vue").then(m => m.default || m)
  },
  {
    name: publicationsn6piZ0ljqSMeta?.name ?? "publications___en",
    path: publicationsn6piZ0ljqSMeta?.path ?? "/publications",
    children: [
  {
    name: _91slug_93kyykW2ogSCMeta?.name ?? "publications-slug___en",
    path: _91slug_93kyykW2ogSCMeta?.path ?? ":slug()",
    meta: _91slug_93kyykW2ogSCMeta || {},
    alias: _91slug_93kyykW2ogSCMeta?.alias || [],
    redirect: _91slug_93kyykW2ogSCMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/publications/[slug].vue").then(m => m.default || m)
  }
],
    meta: publicationsn6piZ0ljqSMeta || {},
    alias: publicationsn6piZ0ljqSMeta?.alias || [],
    redirect: publicationsn6piZ0ljqSMeta?.redirect || undefined,
    component: () => import("/workspace/nuxt/pages/publications.vue").then(m => m.default || m)
  }
]